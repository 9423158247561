<template>
  <BasePageContent>
    <CustomerDetail :uuid="uuid"></CustomerDetail>
  </BasePageContent>
</template>

<script>
import CustomerDetail from "@/components/ui/customer/CustomerDetail.vue";
export default {
  components: { CustomerDetail },
  props: {
    uuid: {
      type: String,
    },
  },
};
</script>