<template>
  <BasePageContent>
    <BasePageHeader
      class="px-3"
      sectiontitle="CUSTOMER DETAIL"
      :title="!Util.isEmpty(Customer) ? Customer.customer_name : ''"
      :description="!Util.isEmpty(Customer) ? Customer.email : ''"
    >
      <ul class="nk-block-tools g-1">
        <template v-if="!Util.isEmpty(Customer)">
          <li class="nk-block-tools-opt">
            <router-link
              :to="{ name: 'customer-edit', params: { uuid: Customer.uuid } }"
              class="btn w3-theme w3-hover-theme btn-dim btn-outline-light"
            >
              <em class="icon ni ni-edit"></em>
              <span>Edit</span>
            </router-link>
          </li>
          <li>
            <div class="drodown">
              <a
                href="#"
                class="dropdown-toggle btn btn-white btn-dim btn-outline-light"
                data-toggle="dropdown"
              >
                <em class="icon ni ni-more-h"></em
              ></a>
              <div class="dropdown-menu dropdown-menu-right">
                <ul class="link-list-opt no-bdr">
                  <li>
                    <a title="Email Customer" href="#">
                      <em class="dd-indc icon ni ni-mail-fill"></em>
                      <span>Email Customer</span>
                    </a>
                  </li>
                  <li>
                    <a title="Create New Invoice" href="#">
                      <em class="dd-indc icon ni ni-file"></em>
                      <span>New Invoice</span>
                    </a>
                  </li>
                  <li>
                    <a title="Create New Invoice" href="#">
                      <em class="dd-indc icon fas fa-file-alt"></em>
                      <span>New Estimate</span>
                    </a>
                  </li>
                  <li>
                    <a
                      title="Delete"
                      class="w3-text-red w3-hover-text-black"
                      @click.prevent="DeleteDetail(Customer.uuid)"
                      href="#"
                    >
                      <em class="dd-indc icon ni ni-trash"></em>
                      <span> Delete Customer</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </li>

          <li class="nk-block-tools-opt">
            <router-link :to="{ name: 'customers' }" class="px-2" title="Back">
              <em class="icon ni ni-cross size36"></em>
            </router-link>
          </li>
        </template>
      </ul>
    </BasePageHeader>
    <div class="card-inner card-inner-md">
      <BaseIndicator
        text="Fetching customer detail..."
        class="my-4"
        v-if="Util.fetching('getCustomer')"
        :size="50"
      />
      <template v-else>
        <template v-if="!Util.isEmpty(Customer)">
          <div
            class="
              w3-white
              py-0
              px-4
              w3-border-bottom
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="d-flex align-items-center justify-content-between">
              <button
                v-for="view in Views"
                :key="view"
                @click.prevent="SwitchView(view)"
                class="w3-button px-2 pt-3 w3-hover-transparent"
                :class="[
                  ActiveView === view
                    ? 'pb-1 w3-bottombar w3-border-theme'
                    : ' pb-2',
                ]"
              >
                <i
                  class="w3-text-theme w3-hover-text-theme"
                  :class="ViewsIcons[view]"
                />
                <span class="w3-hide-small ml-1">{{ view }}</span>
              </button>
            </div>
          </div>
          <div class="p-md-5 p-3">
            <div v-if="IsActiveView('Contact')" class="">
              <h4 class="mb-2">
                <i class="mr-1" :class="ViewsIcons['Contact']" /> Contact
                Information
              </h4>
              <div class="row">
                <div class="col-md-6">
                  <div class="p-1">
                    <div class="row w3-border-bottom">
                      <div class="col-sm-4 w3-mobile px-2 pt-2 bold size13">
                        Customer name
                      </div>
                      <div class="col-sm-8 w3-mobile px-2 pt-md-2 pb-2">
                        {{ Customer.customer_name ?? "-" }}
                      </div>
                    </div>
                    <div class="row w3-border-bottom">
                      <div class="col-sm-4 w3-mobile px-2 pt-2 bold size13">
                        First name
                      </div>
                      <div class="col-sm-8 w3-mobile px-2 pt-md-2 pb-2">
                        {{ Customer.firstname ?? "-" }}
                      </div>
                    </div>
                    <div class="row w3-border-bottom">
                      <div class="col-sm-4 w3-mobile px-2 pt-2 bold size13">
                        Last name
                      </div>
                      <div class="col-sm-8 w3-mobile px-2 pt-md-2 pb-2">
                        {{ Customer.lastname ?? "-" }}
                      </div>
                    </div>
                    <div class="row w3-border-bottom">
                      <div class="col-sm-4 w3-mobile px-2 pt-2 bold size13">
                        Mobile
                      </div>
                      <div class="col-sm-8 w3-mobile px-2 pt-md-2 pb-2">
                        {{ Customer.mobile ?? "-" }}
                      </div>
                    </div>
                    <div class="row w3-border-bottom">
                      <div class="col-sm-4 w3-mobile px-2 pt-2 bold size13">
                        Telephone
                      </div>
                      <div class="col-sm-8 w3-mobile px-2 pt-md-2 pb-2">
                        {{ Customer.telephone ?? "-" }}
                      </div>
                    </div>
                    <div class="row w3-border-bottom">
                      <div class="col-sm-4 w3-mobile px-2 pt-2 bold size13">
                        Fax
                      </div>
                      <div class="col-sm-8 w3-mobile px-2 pt-md-2 pb-2">
                        {{ Customer.fax ?? "-" }}
                      </div>
                    </div>
                    <div class="row w3-border-bottom">
                      <div class="col-sm-4 w3-mobile px-2 pt-2 bold size13">
                        Email
                      </div>
                      <div class="col-sm-8 w3-mobile px-2 pt-md-2 pb-2">
                        {{ Customer.email ?? "-" }}
                      </div>
                    </div>
                    <div class="row w3-border-bottom">
                      <div class="col-sm-4 w3-mobile px-2 pt-2 bold size13">
                        Website
                      </div>
                      <div class="col-sm-8 w3-mobile px-2 pt-md-2 pb-2">
                        {{ Customer.website ?? "-" }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="p-1">
                    <div class="row w3-border-bottom">
                      <div class="col-sm-12 w3-mobile px-2 pt-2 bold size13">
                        Internal Note
                      </div>
                      <div
                        class="
                          col-sm-12
                          w3-mobile
                          px-2
                          pt-md-2
                          pb-2
                          min-height140
                        "
                        v-html="Util.nl2br(Customer.internal_note) ?? '-'"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="IsActiveView('Address')" class="">
              <h4 class="mb-2">
                <i class="mr-1" :class="ViewsIcons['Address']" /> Address
                Information
              </h4>
              <div class="row">
                <div class="col-md-6">
                  <div class="p-1">
                    <div class="row w3-border-bottom">
                      <div class="col-sm-4 w3-mobile px-2 pt-2 bold size13">
                        Billing Address
                      </div>
                      <div class="col-sm-8 w3-mobile px-2 pt-md-2 pb-2">
                        {{ Customer.billing_address ?? "-" }}
                      </div>
                    </div>
                    <div class="row w3-border-bottom">
                      <div class="col-sm-4 w3-mobile px-2 pt-2 bold size13">
                        City
                      </div>
                      <div class="col-sm-8 w3-mobile px-2 pt-md-2 pb-2">
                        {{ Customer.billing_city ?? "-" }}
                      </div>
                    </div>
                    <div class="row w3-border-bottom">
                      <div class="col-sm-4 w3-mobile px-2 pt-2 bold size13">
                        State
                      </div>
                      <div class="col-sm-8 w3-mobile px-2 pt-md-2 pb-2">
                        {{ Customer.billing_state_name ?? "-" }}
                      </div>
                    </div>
                    <div class="row w3-border-bottom">
                      <div class="col-sm-4 w3-mobile px-2 pt-2 bold size13">
                        Zip/Postcode
                      </div>
                      <div class="col-sm-8 w3-mobile px-2 pt-md-2 pb-2">
                        {{ Customer.billing_postcode ?? "-" }}
                      </div>
                    </div>
                    <div class="row w3-border-bottom">
                      <div class="col-sm-4 w3-mobile px-2 pt-2 bold size13">
                        Country
                      </div>
                      <div class="col-sm-8 w3-mobile px-2 pt-md-2 pb-2">
                        {{ Customer.billing_country_name ?? "-" }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="p-1">
                    <div class="row w3-border-bottom">
                      <div class="col-sm-4 w3-mobile px-2 pt-2 bold size13">
                        Shipping Address
                      </div>
                      <div class="col-sm-8 w3-mobile px-2 pt-md-2 pb-2">
                        {{ Customer.display_shipping_address ?? "-" }}
                      </div>
                    </div>
                    <div class="row w3-border-bottom">
                      <div class="col-sm-4 w3-mobile px-2 pt-2 bold size13">
                        City
                      </div>
                      <div class="col-sm-8 w3-mobile px-2 pt-md-2 pb-2">
                        {{ Customer.display_shipping_city ?? "-" }}
                      </div>
                    </div>
                    <div class="row w3-border-bottom">
                      <div class="col-sm-4 w3-mobile px-2 pt-2 bold size13">
                        State
                      </div>
                      <div class="col-sm-8 w3-mobile px-2 pt-md-2 pb-2">
                        {{ Customer.display_shipping_state_name ?? "-" }}
                      </div>
                    </div>
                    <div class="row w3-border-bottom">
                      <div class="col-sm-4 w3-mobile px-2 pt-2 bold size13">
                        Zip/Postcode
                      </div>
                      <div class="col-sm-8 w3-mobile px-2 pt-md-2 pb-2">
                        {{ Customer.display_shipping_postcode ?? "-" }}
                      </div>
                    </div>
                    <div class="row w3-border-bottom">
                      <div class="col-sm-4 w3-mobile px-2 pt-2 bold size13">
                        Country
                      </div>
                      <div class="col-sm-8 w3-mobile px-2 pt-md-2 pb-2">
                        {{ Customer.display_shipping_country_name ?? "-" }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="IsActiveView('Invoices')" class="">
              <h4 class="mb-2">
                <i class="mr-1" :class="ViewsIcons['Invoices']" /> Customer
                Invoices
              </h4>
              <div class="row">
                <div class="col-md-6">
                  <div class="p-1"></div>
                </div>
                <div class="col-md-6">
                  <div class="p-1"></div>
                </div>
              </div>
            </div>
            <div v-if="IsActiveView('Estimates')" class="">
              <h4 class="mb-2">
                <i class="mr-1" :class="ViewsIcons['Estimates']" /> Customer
                Estimates
              </h4>
              <div class="row">
                <div class="col-md-6">
                  <div class="p-1"></div>
                </div>
                <div class="col-md-6">
                  <div class="p-1"></div>
                </div>
              </div>
            </div>
            <div v-if="IsActiveView('Transactions')" class="">
              <h4 class="mb-2">
                <i class="mr-1" :class="ViewsIcons['Transactions']" /> Customer
                Transactions
              </h4>
              <div class="row">
                <div class="col-md-6">
                  <div class="p-1"></div>
                </div>
                <div class="col-md-6">
                  <div class="p-1"></div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div v-else class="p-5 w3-center">
          <img src="/images/item-not-found.png" class="mb-4" />
          <h4>Customer not found</h4>
          <router-link
            :to="{ name: 'customers' }"
            class="w3-button w3-white w3-border"
          >
            <span class="">Manage Customers</span>
          </router-link>
        </div>
      </template>
    </div>
  </BasePageContent>
</template>

<script>
import { ref, toRef } from "vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
import { Util } from "@/helpers/utilities";
// import CustomerUpdate from "@/components/ui/customer/CustomerUpdate.vue";
// import Aside from "@/components/ui/Aside.vue";
export default {
  components: {
    // Aside,
    // CustomerUpdate
  },
  props: {
    customer: {
      type: Object,
    },
    uuid: {
      type: String,
    },
  },
  emits: ["oncancel"],
  setup(props) {
    const store = useStore();
    // const router = useRouter();
    const fetching = ref(true);
    const Customer = ref({});
    const CUSTOMER = toRef(props, "customer");
    const GetCustomer = async () => {
      Customer.value = {};
      if (props.uuid) {
        const _customer = await store.dispatch(
          "customers/getCustomer",
          props.uuid
        );
        Customer.value = {
          ..._customer,
        };
      } else if (CUSTOMER.value) {
        Customer.value = CUSTOMER.value;
      } else {
        Customer.value = {};
      }
      fetching.value = false;
    };
    GetCustomer();
    // TAB VIEWS
    const Views = [
      "Contact",
      "Address",
      "Invoices",
      "Estimates",
      "Transactions",
    ];
    const ResourcesViews = ["New Invoice", "New Estimate"];
    const ActionViews = ["Edit", "Delete"];
    const ViewsIcons = {
      Contact: "fas fa-user-tie",
      Address: "fas fa-map-marker-alt",
      Invoices: "fas fa-file-invoice",
      Estimates: "fas fa-file-alt",
      Transactions: "icon bx bx-line-chart",
    };
    const ActiveView = ref(Views[0]);
    const IsActiveView = (view) => (ActiveView.value === view ? true : false);
    const SwitchView = (view) => {
      const newViewIndex = Views.indexOf(view);
      if (ActiveView.value === Views[newViewIndex]) return false;
      ActiveView.value = Views[newViewIndex];
      return true;
    };

    const DeleteCustomer = (uuid) => {
      console.log(uuid);
    };
    const OnCancel = () => {
      if (props.uuid) {
        // router.push({
        //   name: "customers",
        // });
      } else {
        // emit("oncancel");
      }
    };
    //  END TAB VIEWS

    return {
      store,
      fetching,
      Customer,
      OnCancel,
      Views,
      ActionViews,
      ResourcesViews,
      ViewsIcons,
      IsActiveView,
      ActiveView,
      SwitchView,
      Util,
      DeleteCustomer,
    };
  },
};
</script>